.body-container {
    height: 100%;
    width: 100%;
    padding-top: 25px;
    position: relative;
}
@media only screen and (max-width: 575px) {
    .body-container{
        padding: 10px 20px 0 20px;
    }
}

.title-section{
    position: relative;
    z-index: 2;
    margin-top: 5%;
    text-align: left;
}
.title-section div{
    display: flex;
    color: var(--highlight-colour);
    align-items: center;
}
.title-section div svg{
    width: 4rem;
    height: 3rem;
}
.title-section p{
    color: var(--highlight-colour);
    font-size: 2.5rem;
    margin-bottom: 0;
}
.title-section h2{
    font-size: 5rem;
}

.bg-section{
    z-index: 0;
    text-align: left;
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 5%;
    color: #222222;
}
.bg-section h1{
    writing-mode: vertical-rl;
    text-orientation: sideways;
    margin: -10px;
    margin-right: 0px;
    font-size: 7rem;
    font-weight: 700;
}

/* https://stackoverflow.com/questions/2310734/how-to-make-html-text-unselectable */
.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.clickable{
    cursor: pointer;
}

/* Transitions */
.page-transition{
    position: fixed;
    /* transform: rotate3d(0, 0, 1, 45deg); */
    height: 200%;
    width: 0%;
    pointer-events: none;
}

.pt-1t{transition: 0.5s ease-in-out;}
.pt-2t{transition: 0.45s ease-in-out;}
.pt-3t{transition: 0.4s ease-in-out;}
.pt-1{
    z-index: 10;
    background-color: #444;
}
.pt-2{
    z-index: 11;
    background-color: var(--highlight-colour);
}
.pt-3{
    z-index: 12;
    background-color: var(--main-bg-color);
}

.transition-active{
    width: 100%;

}