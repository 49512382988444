.project-container{
    margin: 1rem;
    display: flex;
    text-align: left;
    background-color: var(--main-bg-color);
    height: 10rem;
}

.project-container img{
    width: 10rem;
    height: 10rem;
    filter: grayscale(100%);
    transition: ease-in-out 0.2s;
}
.project-container img:hover{
    filter: none;
}
.project-container div:nth-child(2){
    margin: 0.5rem 1rem;
    width: 100%;
}

.project-header{
    margin: 0;
    display: flex;
    justify-content: space-between;
}
.project-link{
    text-decoration:underline var(--highlight-colour);
    transition: ease-in-out 0.2s;
}
.project-link h3{
    color: white !important;
}
.project-link h3:hover{
    color: var(--highlight-colour) !important;
}
.project-header h3{
    color:var(--highlight-colour);
    margin: 0;
}
.project-sub{
    font-style: italic;
    font-weight: 300;
    margin: 0;
}
.project-desc{
    margin: 0;
}