.about-me-container{
    margin: 2rem 0;
    z-index: 1;
    position: relative;
    display: flex;
    text-align: left;
}
.about-me-desc{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.me{
    height: 20rem;
    width: 20rem;
    filter:grayscale();
    margin: 0 2rem 0 0;
}

.degree-container{
    display:flex;
    padding:1rem;
    background-color: var(--secondary-bg-color);
}
.degree-container img{
    height: 7rem;
}
.degree-container h3{
    margin-bottom: 5px;
}
.degree-container div{
    padding: 0 1rem;
}

.career-container{
    text-align: left;
    padding-bottom: 5rem;
}

@media only screen and (max-width: 1399px) {
    .about-me-desc{
        width: 100%;
    }
}
@media only screen and (max-width: 768px) {
    .about-me-container{
        flex-direction: column;
    }
    .me{
        width: 25rem;
        height: 25rem;
        margin:0 auto 2rem auto;
    }
    .about-me-desc div:first-child{
        padding: 1rem;
        background-color: var(--secondary-bg-color);
    }
    .about-me-desc div:first-child h3{
        text-align: center;
    }
    /* .about-me-desc div:first-child p{
        padding: 0 4rem;
    } */
    .degree-container{
        margin-top: 2rem;
    }
}