html {
  --main-bg-color: #151515;
  --text-colour: #ffffff;
  --highlight-colour: #FFF500;
  --secondary-bg-color: #222222;
  background-color: var(--main-bg-color);
  height: 100%;
  width: 100%;
  margin: 0%;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'Zilla Slab', serif;
  color: var(--text-colour);
  background-color: var(--main-bg-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 991px) {
  html{
      font-size: small;
  }
}

@media only screen and (max-width: 767px) {
  html{
      font-size: x-small;
  }
}