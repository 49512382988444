.job-container {
    width: 100%;
}
.job-header {
    padding:0.4rem 1rem;
    margin: 0;
    background-color: var(--secondary-bg-color);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0;
    border-top: 4px solid var(--main-bg-color);
}
.job-header div{
    display: flex;
    align-items: center;
}
.job-header div h2{
    margin-bottom:0; 
}
.job-title-left h2:nth-child(1){
    color: var(--highlight-colour);
    margin-right: 1rem;
}
.job-title-left h2:nth-child(2){
    font-weight: 300;
}
.job-title-right{
    text-align: right;
}
.job-title-right h2{
    margin-right: 1rem;
    position: relative;
    top: -3px;
    font-weight: 300;
}
.job-title-right svg{
    transition: 0.5s;
    height: 100%;
    width: 2rem;
}
.job-chevron-selected{
    transform: rotate3d(1,0,0,180deg);
}
.job-dropdown-container{
    transition: 0.3s ease-in-out;
    display: flex;
    background-color: var(--secondary-bg-color);
    background-color: #272727;
    width: 100%;
    height: 0;
    margin: 0;
    padding: 0 1rem;
    top: 0;
    align-items: center;
    overflow: hidden;
}
.job-dropdown-container-selected{
    height: 9rem;
}
.job-desc-container{
    padding: 0 1rem 0 2rem;
}
.job-desc-li{
    padding: 0;
    margin: 0;
}
.job-image{
    height: 7rem;
    transition: ease-in-out 0.25s;
    filter: grayscale();
}
.job-image:hover{
    filter:none;
}

@media only screen and (max-width: 768px) {
    .job-image{
        height: 7rem;
    }
}

@media only screen and (max-width: 1200px) {
    .job-title-left{
        flex-direction: column;
        align-items: flex-start !important;
    }
}