.projects-container{
    margin: 4rem 0;
    z-index: 2;
    position: relative;
    background-color: var(--secondary-bg-color);
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 1200px) {
    .content-container{
        grid-template-columns: 1fr;
    }
}